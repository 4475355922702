.section-bg {
    width: 110%;
    margin-left:-5%;
    padding:40px 0px;
    transform: rotate(1.3deg);
    &.white{
        background:#fff;
    }
    & .container {
        transform: rotate(-1.3deg);
    }
}

.section-bg-reverse {
    width: 110%;
    margin-left:-5%;
    padding:40px 0px;
    transform: rotate(-1.3deg);
    &.white{
        background:#fff;
    }
    & .container {
        transform: rotate(1.3deg);

        & .row{
            display: flex;
            flex-direction: row-reverse;
        }
    }
    
}

.special-section{
    margin: -120px 0;
    position: relative;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items:center;
    overflow: hidden;
    & .container{
        position: absolute;
        z-index: 3;
    }
    & img {
        object-fit: cover;
        object-position:70% bottom;
        min-height: 100vh;
    }
    .pfad {
        position: absolute;
        right:35vw;
        bottom:-100px;
        height:100%;
        z-index: 1;
        object-position: contain;
        object-position: top right;
        @media screen and (max-width:786px){
            right: 0vw;
        }
    }
}