.header {
    position: relative;

    & .menu {
        margin-right: 12px;
        cursor: pointer;
        transition: ease all .5s;
        &:hover{
            transform: scale(1.2);
        }
    }

    & .wrapper {
        min-height: 600px;

        @media screen and (max-width:786px) {
            min-height: 440px;
        }
    }

    & .info {
        min-height: 35vh;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-end;
        @media screen and (max-width:786px) {
            min-height: 400px;
        }

        & ::-moz-selection {
            /* Code for Firefox */
            color: #fff;
            background: #5FB8B5;
        }

        & ::selection {
            color: #fff;
            background: #5FB8B5;
        }
    }

    & h1 {
        color: #fff;
        width: 100%;
        text-align: end;
        padding-right: 25%;
        font-size: 3.2vw;
        transition: ease all .3s;
        &:hover{
            transform: scale(1.1);
        }
        & ::-moz-selection {
            color: #5FB8B5;
            background: #fff;
        }

        & ::selection {
            color: #5FB8B5;
            background: #fff;
        }

        @media screen and (max-width:786px) {
            font-size: 24px;
        }
    }

    .whatsapp {
        text-decoration: none;
        color: #fff;
    }

    .phone {
        font-size: 16px;
        text-decoration: none;
        color: #fff;
        margin-left: 12px;
        
    }

    & h2 {
        color: #fff;
        font-family: 'Caveat', cursive;
        text-align: end;
        width: 100%;
        font-size: 3.125vw;
        margin: 0;
        padding-right: 5%;
        margin-top: -1.3vw;
        transition: ease all .3s;
        &:hover{
            transform: scale(1.1);
        }
        @media screen and (max-width:786px) {
            font-size: 36px;
        }
    }

    & .figure-next {
        position: absolute;
        right: 8vw;
        top: 200px;
        width: 300px;
        max-width: 400px;

        @media screen and (max-width:786px) {
            width: 200px;
        }
    }

    & .figure-header {
        position: absolute;
        left: -10vw;
        top: -100px;
        z-index: -1;
        width: 85%;
        min-height: 600px;
        object-fit: cover;
        object-position: bottom right;

        @media screen and (max-width:786px) {
            left: -7vw;
            width: 100%;
            top: -40px;
        }
    }

    & .figure-content {
        min-height: 380px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        @media screen and (max-width:786px) {
            min-height: 180px;
        }
        & h1, h2 {
            cursor: pointer;
        }
    }

    & ::-moz-selection {
        color: #5FB8B5;
        background: #fff;
    }

    & ::selection {
        color: #5FB8B5;
        background: #fff;
    }
}

.drawer-menu {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: -100vh;
    left:0;
    background: #5FB8B5;
    color: #fff;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: ease all .5s;
    &.active{
        top:0;
        transition: ease all .5s;
    }
    & .container{
        height:100vh;
        display: flex;
        justify-content: center;
        align-items: center;   
    }
    & .close {
        position: absolute;
        top: 48px;
        left:22px;
        height: 42px;
        width: 42px;
        cursor: pointer;
        transition: ease all .5s;
        &:hover{
            transform: scale(1.1);
        }
    }
    & ul {
        list-style: none;
        padding: 0;
        margin: 0;
        & li {    
            padding: 0;
            margin: 0;
            text-align: center;  
            & a {   
                text-align: center;  
                 text-decoration: none;
                 color: #fff;
            }
        }
    }
}