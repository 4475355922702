@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400;700&family=Nunito:wght@300;400;700&display=swap');
@import "./header";
@import "./content";
@import "./footer";


body,
html,
#root {
    font-family: 'Nunito', sans-serif;
    font-size: 32px;
    margin: 0;
    padding: 0;
    background: #E4ECF8;
    position: relative;
    z-index: -2;
    color: #535353;
    background: linear-gradient(13deg, #E4ECF8, #EEEDE9);
}

::-moz-selection {
    /* Code for Firefox */
    color: #fff;
    background: #5FB8B5;
}

::selection {
    color: #fff;
    background: #5FB8B5;
}

.site-wrapper {
    position: relative;
}

.main {
    min-height: 50vh;

    &.single-page {
        font-size: 18px;
        margin: 0;
        padding: 0;
        & ul {
            padding: 0;
            margin: 0;
            margin-left: 20px;
        }
        @media screen and (max-width:786px) {
            margin-top: 110px;
            & h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                text-align: center;
            }

            & a {
                hyphens: auto;
            }
        }

        & p {
            font-size: 22px;
            margin: 0;
            padding: 0;
        }

        & h2,
        h3,
        h4,
        strong {
            font-size: 32px;
            font-weight: bold;
            font-family: 'Nunito', sans-serif;
            margin: 20px 0;
        }
    }
}

p {
    margin: 0 10px;
}

h3 {
    font-family: 'Caveat', cursive;
    font-size: 70px;
    margin: 0;
    line-height: 65px;

    & small {
        font-size: 40px;
        margin: 0;
        line-height: 35px;
    }

    @media screen and (max-width:786px) {
        font-size: 36px;
        line-height: 40px;
        text-align: center;

        & small {
            font-size: 24px;
            margin: 0;
        }
    }
}

p {
    font-family: 'Nunito', sans-serif;
    font-size: 32px;

    @media screen and (max-width:786px) {
        font-size: 24px;
        text-align: center;
    }
}

.scroll-top-btn{
    display: flex;
    align-items: center;
    justify-content:center;
    position: fixed;
    bottom:-32px;
    right: 32px;
    height: 64px;
    width: 64px;
    background:#5FB8B5;
    border-radius: 64px;
    z-index: 88;
    opacity:0;
    transition:ease all 0.3s;
    cursor: pointer;
    &.active{
        opacity:1;
        bottom: 32px;
    }
}