.footer {
    width: 106%;
    background:#fff;
    margin-left:-5%;
    margin-bottom: -100px;
    padding:40px 0px;
    transform: rotate(1.3deg);
    overflow: hidden;
    & .container {
        transform: rotate(-1.3deg);
        width: 106%;
    }
    & .disclaimer{
        font-size: 16px;
        margin: 0;
        padding: 0;
    }
    & ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        margin: 0;
        padding: 0;
        @media screen and (max-width:786px){
            flex-direction: column;
        }
        & li {
            margin: 0;
            padding: 0;
            margin-right: 12px;
            @media screen and (max-width:786px){
                margin-right: 0;
            }
            & a {
                font-size: 22px;
                text-decoration: none;
                color: #535353;
            }
        }
    }
}
.overflow-x-hidden{
    overflow: hidden;
    padding: 80px 0 ;
}